import { useState } from "react";
import {
  ICollection,
  PriceRangeType,
  IMarketState,
  IPlayerMetadata,
  PlayerFiltersType,
  SortByType,
} from "@/types";

const initialState = {
  topPerformers: null,
  marketData: [],
  teamsData: null,
  searchData: [],
  sortBy: null,
};

const MarketState = () => {
  const [data, setData] = useState<IMarketState>(initialState);
  const [page, setPage] = useState(1)
  const [filters, setFilters] = useState({})
  const [sortBy, setSortBy] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const actions = {
    setTopPerformers: (topPerformers: ICollection[]) =>
      setData((data) => ({ ...data, topPerformers })),
    setMarketdata: (marketData: ICollection[]) =>
      setData((data) => ({ ...data, marketData })),
    setMarketTeamsData: (teamsData: ICollection[]) =>
      setData((data) => ({ ...data, teamsData })),
    setSearchData: (searchData: IPlayerMetadata[]) =>
      setData((data) => ({ ...data, searchData })),
    setMarketIsLoading: setIsLoading,
    setFiltersMarketplace: setFilters,
    setSortMarketplace: setSortBy,
    setPriceRange: (priceRange: PriceRangeType) =>
      setData((data) => ({ ...data, priceRange })),
    setMinMaxRating: (rating: PriceRangeType) =>
      setData((data) => ({ ...data, rating })),
    setMarketPage: setPage,
  };

  return {
    data: {
      ...data,
      page,
      filters,
      sortBy,
      isLoading
    },
    actions,
  };
};

export default MarketState;
