import { useState } from "react";
import { IContestState } from "@/types";
import { ContestAll, ContestInfo } from "@/types/CompetitionTypes";

const initialState = {
  allContests: [],
  contests: [],
  history: [],
  isLoading: true,
};

export const initialPageState = {
  selectedPosition: undefined,
  // lineup: [],
  contest: null,
  isLoading: false,
  contestInfo: null,
  currentTeam: null,
  team: [],
  seasonGames: [],
  isEdit: false,
  playerScores: [],
  myEntry: null,
  currentEntry: null,
}

export type LineupType = {
  label: string
  position: string
  index: number
  player: null | any
}

export type PageStateType = {
  selectedPosition: null | number
  // lineup: LineupType[]
  contest: ContestAll | null
  isLoading: boolean
  contestInfo: ContestInfo | null
  currentTeam: any
  team: any[]
  seasonGames: any[]
  isEdit: boolean
  playerScores: any[]
  myEntry: any,
  currentEntry
}

const ContestState = () => {
  const [data, setData] = useState<IContestState>(initialState);
  const [pageState, setPageState] = useState<PageStateType>(initialPageState)
  const [contestLineup, setContestLineup] = useState<LineupType[]>([])
  const [contestPage, setContestPage] = useState<ContestAll>()

  const actions = {
    setContests: (data: IContestState) => setData(data),
    setSelectedPosition: (position: number) => setPageState((prev) => ({
      ...prev,
      selectedPosition: position
    })),
    setLineup: setContestLineup,
    setContest: setContestPage,
    setContestPageIsLoading: (isLoading: boolean) => setPageState((prev) => ({
      ...prev,
      isLoading
    })),
    setContestInfo: (contestInfo: ContestInfo) => setPageState((prev) => ({
      ...prev,
      contestInfo
    })),
    setCurrentTeam: (currentTeam: any) => setPageState((prev) => ({
      ...prev,
      currentTeam
    })),
    setTeam: (team: any) => setPageState((prev) => ({
      ...prev,
      team
    })),
    setSeasonGames: (seasonGames: any[]) => setPageState((prev) => ({
      ...prev,
      seasonGames
    })),
    setIsEdit: (isEdit: boolean) => setPageState((prev) => ({
      ...prev,
      isEdit
    })),
    setPlayerScores: (playerScores: any[]) => setPageState((prev) => ({
      ...prev,
      playerScores
    })),
    setContestEntry: (myEntry: any) => setPageState((prev) => ({
      ...prev,
      myEntry
    })),
    setContestCurrentEntry:(currentEntry: any) => setPageState((prev) => ({
      ...prev,
      currentEntry
    })),
    resetContestPageState: () => setPageState(initialPageState)
  };

  return {
    data: {
      ...data,
      pageState: {
        ...pageState,
        lineup: contestLineup,
        contest: contestPage
      },
    },
    actions,
  };
};

export default ContestState;
