import { useState } from "react";
import {
  ICollectionState,
  PlayerFiltersType,
  SortByType,
  PriceRangeType,
} from "@/types";

const initialState = {
  collection: [],
  filters: {},
  page: 1,
};

const CollectionState = () => {
  const [data, setData] = useState<ICollectionState>(initialState);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<boolean>(null);

  const actions = {
    setCollection: (data: ICollectionState) => setData(data),
    // setIsLoading: (loading: boolean) =>
    //   setData((d) => ({ ...d, isLoading: loading })),
    setFiltersCollection: (filters: PlayerFiltersType) =>
      setData((d) => ({ ...d, filters })),
    setSortCollection: setSortBy,
    setPriceRangeCollection: (priceRange: PriceRangeType) =>
      setData((data) => ({ ...data, priceRange })),
    setRatingCollection: (priceRange: PriceRangeType) =>
      setData((data) => ({ ...data, priceRange })),
    setCollectionPage: (page: number) => setData((data) => ({ ...data, page })),
    setCollectionIsLoading: setIsLoading
  };

  return {
    data: {
      ...data,
      isLoading,
      sortBy
    },
    actions,
  };
};

export default CollectionState;
