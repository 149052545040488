import type { AppProps } from "next/app";
import type { Session } from "next-auth";
import Script from "next/script";
import { Toaster } from "react-hot-toast";

import Router from "next/router";
import NProgress from "nprogress"; //nprogress module
import { SessionProvider } from "next-auth/react";

import "nprogress/nprogress.css"; //styles of nprogress
import "../styles/globals.css";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import "swiper/css/virtual";
import "tippy.js/dist/tippy.css";
import { ContextWrapper } from "@/context";
import Head from "next/head";

//Route Events.

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

function MyApp({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps<{ session: Session }>) {
  return (
    <SessionProvider session={session}>
      <ContextWrapper>
        <Head>
          <Script strategy="lazyOnload">
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-18TS21CJ6L', {
              page_path: window.location.pathname,
              });
            `}
          </Script>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
          />
        </Head>
        <Component {...pageProps} />
        <Toaster position="top-center" reverseOrder={false} />
      </ContextWrapper>
    </SessionProvider>
  );
}

export default MyApp;
