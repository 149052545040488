import { useState } from "react";

const MiniCamp = () => {
  const [data, setData] = useState<any[]>([]);

  const actions = {
    setMinicampPlayers: (values: any) => setData(values),
  };

  return {
    data,
    actions,
  };
};

export default MiniCamp;
